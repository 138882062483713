import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-586da5b6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "topbutton"
};
import { ref } from 'vue';
import { Delete, Edit, Refresh, Plus } from '@element-plus/icons-vue';
import modalNewlyMenuRule from './modal-newlyMenuRule'; // 新增

import { ElMessage } from 'element-plus';
export default {
  __name: 'MenuRule',

  setup(__props) {
    const tableData = [{
      id: 1,
      name: 'qweqwqwe',
      status: true,
      title: '控制台',
      time: '2022-02-03',
      type: '菜单',
      icon: 'UserFilled',
      children: [{
        id: 2,
        name: 'qweqwqwe',
        status: true,
        title: '控制台1',
        time: '2022-02-03',
        type: '菜单',
        icon: 'UserFilled',
        children: [{
          id: 3,
          name: 'qweqwssssqwe',
          status: true,
          title: '控制台1',
          time: '2022-02-03',
          type: '菜单',
          icon: 'UserFilled',
          children: [{
            id: 4,
            name: 'qweqwssssqwe',
            status: true,
            title: '控制台4',
            time: '2022-02-03',
            type: '菜单',
            icon: 'UserFilled'
          }]
        }]
      }]
    }]; // 打开服务站名称对话框

    const modalNewlyMenuRuleRef = ref();

    const openModelNewly = record => {
      console.log(record);
      const datainfo = {
        titletext: record
      };
      modalNewlyMenuRuleRef.value.showModal(datainfo);
    }; // 删除的提示


    const popconfirmConfirm = record => {
      ElMessage({
        message: '删除成功',
        type: 'success'
      });
    };

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_tooltip = _resolveComponent("el-tooltip");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_switch = _resolveComponent("el-switch");

      const _component_el_popconfirm = _resolveComponent("el-popconfirm");

      const _component_el_table = _resolveComponent("el-table");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_tooltip, {
        content: "刷新",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          color: "#626aef",
          icon: _unref(Refresh)
        }, null, 8, ["icon"])]),
        _: 1
      }), _createVNode(_component_el_tooltip, {
        content: "添加",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          icon: _unref(Plus),
          color: "#626aef",
          onClick: _cache[0] || (_cache[0] = $event => openModelNewly('添加'))
        }, {
          default: _withCtx(() => [_createTextVNode("添加")]),
          _: 1
        }, 8, ["icon"])]),
        _: 1
      }), _createVNode(_component_el_tooltip, {
        content: "编辑",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          icon: _unref(Edit),
          color: "#626aef"
        }, {
          default: _withCtx(() => [_createTextVNode("编辑")]),
          _: 1
        }, 8, ["icon"])]),
        _: 1
      }), _createVNode(_component_el_tooltip, {
        content: "删除",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          icon: _unref(Delete),
          color: "red"
        }, {
          default: _withCtx(() => [_createTextVNode("删除")]),
          _: 1
        }, 8, ["icon"])]),
        _: 1
      })]), _createVNode(_component_el_table, {
        data: tableData,
        style: {
          "width": "100%",
          "margin-bottom": "20px"
        },
        "row-key": "id",
        border: "",
        "default-expand-all": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "50"
        }), _createVNode(_component_el_table_column, {
          label: "标题",
          prop: "title"
        }), _createVNode(_component_el_table_column, {
          label: "图标",
          width: "70"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            icon: scope.row.icon
          }, null, 8, ["icon"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "名称",
          prop: "name"
        }), _createVNode(_component_el_table_column, {
          label: "类型",
          prop: "type"
        }), _createVNode(_component_el_table_column, {
          label: "修改时间",
          prop: "time"
        }), _createVNode(_component_el_table_column, {
          label: "状态"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_switch, {
            modelValue: scope.status,
            "onUpdate:modelValue": $event => scope.status = $event,
            class: "ml-2"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tooltip, {
            content: "编辑",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              onClick: _cache[1] || (_cache[1] = $event => openModelNewly('编辑')),
              color: "#626aef",
              icon: _unref(Edit)
            }, null, 8, ["icon"])]),
            _: 1
          }), _createVNode(_component_el_tooltip, {
            content: "删除",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_popconfirm, {
              title: "确定要删除记录?",
              onConfirm: popconfirmConfirm
            }, {
              reference: _withCtx(() => [_createVNode(_component_el_button, {
                color: "red",
                icon: _unref(Delete)
              }, null, 8, ["icon"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_unref(modalNewlyMenuRule), {
        ref_key: "modalNewlyMenuRuleRef",
        ref: modalNewlyMenuRuleRef
      }, null, 512)], 64);
    };
  }

};